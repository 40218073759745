<template>
  <Form class="form" @submit="checkForm()">
    <fieldset class="full-width">
      <div class="inputdiv flex row wrap space-between">
          <label class="formtext"><sup>*</sup>Nombre</label>
          <div class="relative-left full-width">
            <Field name="nombre" 
            v-model.trim="nombre" 
            placeholder="Ingrese su nombre" 
            type="text"
            rules="required|min:3" 
            />
            <ErrorMessage name="nombre" />
          </div>
      </div>
      <div class="inputdiv inputdiv_two-cols flex row wrap space-between">
        <div class="itw-item">
          <label class="formtext"><sup>*</sup>Email</label>
          <div class="relative-left full-width">
            <Field name="email" 
            v-model.trim="email" 
            placeholder="Ingrese su correo electrónico" 
            type="email"
            rules="required|email" />
            <ErrorMessage name="email" />
          </div>
        </div>
        <div class="itw-item">
          <label class="formtext">*Celular</label>
          <div class="relative-left full-width">
            <Field name="tel" 
              v-model="telefono" 
              placeholder="Ingrese su celular" 
              type="tel"
              rules="required|min:9|regex:[0-9]{3}[0-9]{3}[0-9]{3}" />
            <ErrorMessage name="tel" />
          </div>
        </div>
     
      </div>
      <div class="inputdiv flex row wrap space-between">
        <label class="formtext"><sup>*</sup>Asunto</label>
        <div class="relative-left full-width">
            <Field name="asunto" 
            v-model.trim="asunto" 
            placeholder="Ingrese el asunto" 
            type="text"
            rules="required|min:4" />
            <ErrorMessage name="asunto" />
          </div>
      </div>
      <div class="inputdiv flex row wrap space-between">
        <label class="formtext"><sup>*</sup>Mensaje</label>
          <div class="relative-left full-width">
            <Field name="mensaje" 
              v-model.trim="mensaje" 
              placeholder="Ingrese su mensaje" 
              type="text"
              rules="required|min:10"
              as="textarea" />
            <ErrorMessage name="mensaje"  />
          </div>
      </div>
      <div class="relative-left full-width">
        <button
        class="enviarmail"
        >
          Enviar mail
        </button>
       <!-- <a
        class="enviarmail"
        href="mailto:contacto@zidarspace.com&subject={{asunto}}&body={{finalText}}"
        >
          Enviar mail
        </a>-->
      </div>
    </fieldset>
  </Form>
</template>
<script>
import { useDbStore } from "@/stores/dbStore";
import { reactive, toRefs, computed } from "vue";
import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
import { required, email, min, regex} from '@vee-validate/rules'
export default {
  setup() {
    const dbStore = useDbStore();
    const dataForm = reactive({
      nombre: "",
      email:"",
      telefono: "",
      asunto: "",
      mensaje: "",
      finalText:computed(()=>{
        let tel = dataForm.telefono ? `Teléfono: ${dataForm.telefono}` : '-';
        return `Nombre: ${dataForm.nombre}, Email: ${dataForm.email}, ${tel}, Asunto: ${dataForm.asunto}, Mensaje: ${dataForm.mensaje} `;
      })
    });
    defineRule('required', required);
    defineRule('email', email);
    defineRule('min', min);
    defineRule('regex', regex);
    function checkForm(){
      let text = `https://wa.me/598093349943/?text=${dataForm.finalText}`
      dataForm.nombre = dataForm.nombre.replace(/[$<>]/g,'');
      dataForm.email = dataForm.email.replace(/[$<>]/g,'');
      dataForm.asunto = dataForm.asunto.replace(/[$<>]/g,'');
      dataForm.mensaje = dataForm.mensaje.replace(/[$<>]/g,'');
      window.location.href = text;
      
    }
    return {
      ...toRefs(dataForm),
      dbStore,
      checkForm
    };
  },
  components:{
    Form,
    Field,
    ErrorMessage 
  }
};
</script>

<template>
  <section class="contacto standard-section bg-blue">
    <div class="container container_md flex row wrap items-center">
      <div class="f-item padding-right-10">
        <h2 class="generalcardtitle2">Contáctanos</h2>
      </div>
      <div class="f-item padding-left-10 flex row wrap x-center">
        <Form />
      </div>
    </div>
  </section>
</template>

<script>
import Form from "@/components/share-tools/Form.vue";
import { reactive, toRefs } from "vue";
import { useDbStore } from "@/stores/dbStore";
export default {
  name: "Contacto",
  components: {
    Form,
  },
  setup() {
    const dbStore = useDbStore();
    const dataProducts = reactive({});

    return {
      ...toRefs(dataProducts),
      dbStore,
    };
  },
};
</script>
